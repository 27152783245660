.language-item {
    display: flex;
    transition: transform 0.5s ease-in-out;
    border-left: 2px solid var(--quaternary-color);
}
.language-item:hover {
    transform: scale(1.1);
}
.column {
    flex: 1;
    padding: 10px;
}
.left-column {
    margin-left: 10%;
}
.right-column {
    font-weight: 500;
}
