.carousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.carousel-image-container {
  position: relative; /* Alinha a imagem dentro do contêiner */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center;     /* Centraliza verticalmente */
}

.carousel-image {
  max-width: 70%;
  max-height: 70%;
  object-fit: contain; /* Ajusta a imagem mantendo as proporções */
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent; /* Remove o fundo padrão do botão */
  border: none;
  cursor: pointer;
  z-index: 2;
  transition: opacity 0.3s ease; /* Transição suave para a opacidade */
  padding: 0; /* Remove o padding para ajustar a imagem */
}

.carousel-button img {
  width: 30px; 
  height: 30px;
  display: block; 
  transition: transform 0.5s ease-in-out; 
}

.carousel-button:hover img {
  opacity: 0.8; 
  transform: scale(1.1);
}

.carousel-button:first-of-type {
  left: 10px;
}

.carousel-button:last-of-type {
  right: 10px;
}