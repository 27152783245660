.image-div {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.image-div img {
    width: 500px;
    height: 500px;
    object-fit: cover; /* Mantém a proporção e preenche o contêiner */
}

.empty-background {
    position: relative;
    background-color: gray;
    width: 500px;
    height: 500px;
    border-radius: 10%;
}

.empty-background img {
    display: block;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: Black;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}