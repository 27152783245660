*{
	font-family: 'Courier New', Courier, monospace;
	font-weight: 300;
}
.curriculum-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.curriculum-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.curriculum-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.curriculum-main {
	display: flex;
}
.curriculum-title {
	width: 100% !important;
	color: #27384c;
	font-weight: 200%;
	font-size: 300%;
}

.curriculum-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 500px;
	margin-left: 0;
}
.curriculum-vertical-line {
    width: 1px;             
    height: 100vh;           
    background-color: #27384c; 

	margin-right: 5%;
}
.curriculum-right-side {
	flex-basis: 695px;
}



.curriculum-section-title {
	width: 80% !important;
	font-weight: 100;
	font-size: 150%;
	margin-top: 5%;
	margin-bottom: 3%;
}

.curriculum-item-container{
	border-left: 2px solid var(--quaternary-color);
	transition: transform 0.5s ease-in-out; 
}
.curriculum-item-container:hover{
	border-left: 2px solid var(--quaternary-color);
	transform: scale(1.1);

}


.curriculum-item-title {
	width: 80% !important;
	font-weight: 400;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	margin-left: 1%;
	white-space: pre-line;
}
.curriculum-item-logo{
	height: 30px;
	border-radius: 20%;
	display: block;
	margin-left: 1%;
	margin-bottom: 2%;
	padding: 0;
}

.curriculum-item-description {
	width: 80% !important;
	margin-left: 10%;
	white-space: pre-line;
}
.curriculum-item-separator {
	margin-top: 15px;
}



.curriculum-image-container {
	width: 370px;
}

.curriculum-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.curriculum-image-wrapper img {
	display: block;
	width: 100%;
}

.curriculum-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.curriculum-socials-mobile {
	display: none;
}

.award-item{
	margin-left: 10%;
	padding-left: 15px;
	transition: transform 0.5s ease-in-out;
}
.award-container{
	padding-right: 40px;
}
.award-item:hover{
	transform: scale(1.1);
}


@media (max-width: 1024px) {
	.curriculum-logo {
		z-index: 100;
	}

	.curriculum-main {
		flex-direction: column;
	}

	.curriculum-subtitle {
		padding-top: 20px !important;
	}

	.curriculum-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.curriculum-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.curriculum-image-container {
		padding-bottom: 40px;
	}

	.curriculum-socials {
		display: none;
	}

	.curriculum-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.curriculum-container {
		padding-top: 60px;
	}

	.curriculum-image-container {
		width: 80%;
	}
}
