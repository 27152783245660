.home-title {
    font-size: 36px; /* Tamanho da fonte, ajuste conforme necessário */
    font-weight: bold; /* Negrito para o título */
    color: #333; /* Cor do texto, ajuste conforme necessário */
    text-align: center; /* Centraliza o texto */
    margin-bottom: 10px; /* Espaçamento inferior */
}

.home-subtitle {
    font-size: 24px; /* Tamanho da fonte, ajuste conforme necessário */
    font-weight: normal; /* Peso normal para o subtítulo */
    color: #666; /* Cor do texto, ajuste conforme necessário */
    text-align: center; /* Centraliza o texto */
    margin-bottom: 20px; /* Espaçamento inferior */
}


.home-buttons-container {
    display: flex;
    justify-content: center; /* Centraliza a div horizontalmente */
    width: 100%; /* Ocupa a largura total do contêiner pai */
}

.home-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 colunas */
    gap: 20px; /* Espaço entre os botões, ajuste conforme necessário */
    width: 400px; /* Largura fixa de 400px */
    padding: 20px; /* Espaço interno, ajuste conforme necessário */
}

.home-buttons button {
    width: 100%; /* Para garantir que os botões ocupem toda a largura da célula */
}

/* LoadingSpinner.css */
.div-spinner {
    display: flex; /* Usa flexbox para centralizar o conteúdo */
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente, opcional se você também quiser centralizar verticalmente */
    height: 500px; /* Ajuste a altura conforme necessário */
}
  
.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
  
  
  