.return-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    padding: 10px 20px;
    margin: 10px;
    text-decoration: none;
    color: #333;
    width: 200px;
    font-weight: 400;
}

.return-button-div:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.return-button-image {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.return-button-text {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-decoration: none;
}

.return-button-div:active {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

a {
    text-decoration: none;
    color: inherit;
}

a:visited {
    color: inherit;
    text-decoration: none;
}
