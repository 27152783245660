/* src/styles/language_dropdown.css */
.dropdown {
  margin-top:70px;
  position: fixed;
  right:10px;
  z-index: 1000;
  display: inline-block;
  

}

.dropdown-toggle {
  padding: 10px 20px;
  background-color: #003b686d;
  color: white;
  border: none;
  cursor: pointer;
}

.dropdown-menu {

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #f9f9f9; /* Cinza quase branco */
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.dropdown-item-image {
  width: 20px;
  width: 20px;  
  max-width: 100%; 
  margin-right: 10px;
}

.dropdown-item:hover {
  background-color: #e9ecef; 
  width: 70%;
}
