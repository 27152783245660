/* src/BeautifulButton.css */
.image-button {
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-weight: bold;
    text-align: center;
  }
  
  .image-button:hover {
    background-color: #3700b3;
    transform: scale(1.05);
  }
  
  .image-button:active {
    background-color: #03dac5;
  }
  